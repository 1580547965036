


<template>
  <common-page-table
    :titleTable="$t('Корпоративная политика')"
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :hasLang="false"
    :prefixEdit="`/corpPolicy`"
    :prefixShow="`/corpPolicy`"
    :linkCreate="`/corpPolicy/create`"
    :keyTable="'policyId'"
    :alternativeConverter="convertData"
    :isDefaultConverter="false"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
  />
</template>
<script>
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import { API_METHODS } from "../../../../constant";
import convertLangDataToTable from "../../../../helpers/converters/convertLangDataToTable";

export default {
  name: "CorpPolicyPage",

  components: { CommonPageTable },
  data() {
    return {
      fieldLang: "",
      apiName: API_METHODS.GET_ALL_POLICY,
      apiDeleteName: API_METHODS.DELETE_POLICY
    };
  },
  computed: {
    paramsGetData() {
      return {
        CompanyId: this.$route.params.CompanyId,
      };
    },
    nameRoleEntity() {
      return NAME_OBJECT.corpPolity;
    }
  },
  methods: {
    convertData(dataNow, langNow) {
      let res = convertLangDataToTable(dataNow, langNow, "", false);
      return res;
    },
    deleteParams(id) {
      return {
        id: id
      };
    }
  }
};
</script>
